/**
 * Dynamically load pollyfill when needed.
 * All runtime polyfills should be added here with an approprietated feature guard
 */
export default async () => {
  const polyfills = []
  let regexpMatchAllPolyfill = Promise.resolve()
  // if (!('allSettled' in Promise)) polyfills.push(import('./all-settled'))
  if (!('FormDataEvent' in window)) polyfills.push(import('./form-data-event'))
  if (!('structuredClone' in window)) polyfills.push(import('./structured-clone'))
  if (!('assignedElements' in HTMLSlotElement.prototype))
    polyfills.push(import('./assigned-elements'))
  if (!(typeof Symbol.matchAll === 'symbol' && Symbol.matchAll in RegExp.prototype))
    polyfills.push((regexpMatchAllPolyfill = import('./match-all/regexp')))

  // Must be imported after ./match-all/regexp
  if (!('matchAll' in String.prototype))
    polyfills.push(regexpMatchAllPolyfill.then(import('./match-all/string')))

  await Promise.all(polyfills)
}
