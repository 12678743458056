import polyfillLoader from './polyfill'

// Import and apply polyfills
polyfillLoader()
  // Import and execute app
  .then(() => import('./app'))
  // Dispatch error event
  .catch((error: Error) => {
    // Handle loading and basic initialization errors
    document.dispatchEvent(
      new ErrorEvent('error', {
        error: error,
        bubbles: false,
        message: error.message,
        composed: false,
        cancelable: false,
      })
    )
  })
